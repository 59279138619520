<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title> Выбор записей из Чек-листа </template>
      <v-card-text style="height: 80vh; overflow: auto" class="pa-0">
        <a-table-f-api
          v-if="!true"
          ref="table"
          :api="url"
          :model="modelGoods"
          :defaults="{
            sort: { key: 'id', order: 'DESC' },
            filters: {
              object_id: object_id,
              'buying.status': 201,
              doc_id: null,
            },
            paramName: 'goods.orderGoods',
          }"
          @click="clickRow"
        />
        <a-table-f-data2
          v-else
          ref="table"
          :dataTable="data"
          :model="modelGoods"
          :useQuery="false"
          :defaults="{
            sort: { key: 'id', order: 'DESC' },
          }"
          @click="clickRow($event)"
        />
      </v-card-text>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="add()" :title="'Добавить'" />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
  </div>
</template>

<script>
import { getAccess } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess],
  props: {
    value: Boolean,
    object_id: Number,
    object_: { type: Object, default: () => {} },
  },
  data() {
    return {
      model: this.$store.getters["config/get"].models.goods.list,
      modelDetail: this.$store.getters["config/get"].models.documentOrderGoods.formDetail,
      url: "/mechti/construction-goods",
      categorys: {},
      data: null,
    };
  },
  computed: {
    modelGoods() {
      let model = [
        {
          name: "checked",
          type: "checkbox",
          width: 35,
          isDisableFunc: r => {
            return r.can_buy;
            //console.log("dsdsd", r.task_status);
            return r?.tasks_status == 201;
          },
        },
        ...this.model.filter(el => {
          return ["id", "name", "category_id", "photo", "vendor", "amount", "measure", "price"].includes(el.name);
        }),
      ];
      return model;
    },
  },
  created() {
    this.fitchData();
  },
  watch: {
    value() {
      if (this.value) {
      }
    },
  },
  methods: {
    async fitchData() {
      let data;
      const d = await this.$axios.get(this.url, {
        params: {
          sort: { key: "id", order: "DESC" },
          filters: {
            object_id: this.object_id,
            "buying.status": 201,
            doc_id: null,
          },
        },
      });
      if (d.data && d.data.data) {
        data = d.data.data;
      }
      for (let i = 0; i < data.length; i++) {
        data[i]["can_buy"] = await this.checkRules(data[i].category_data, data[i].category_id);
      }
      this.data = data;
      this.loading = false;
    },
    async checkRules(rules, category) {
      let can_buy = !true;
      if (!rules) return true;

      rules = JSON.parse(rules)?.buying_rules || null;
      if (!rules) return true;
      let sql = "";
      if (this.categorys.hasOwnProperty(category)) {
        return this.categorys[category];
      }
      rules
        .filter(r => r.type == 1)
        .forEach(rule => {
          let str = rule.services.join(",");
          sql += sql ? " UNION all " : "";
          sql += `SELECT case when COUNT(1)>0 then 1 ELSE 0 END  AS res FROM accounting_doc_act_work WHERE object_id=${this.object_id}  AND  service_id in (${str})`;
        });
      if (!sql) return true;
      sql = `SELECT SUM(res) as res FROM (${sql}) AS t`;
      let resp = await this.$axios.post("accounting/records/report-sql", { q: sql });
      if (resp.data.status == "ok") {
        can_buy = resp.data.data[0].res == rules.length ? true : false;
      }
      this.categorys[category] = can_buy;
      return can_buy;
    },
    clickRow(d) {
      if (d.field.name == "checked") return;
      this.$refs.table.$refs.base.clickCheckbox(d.event, d.row, d.key);
      this.$refs.table.$refs.base.$forceUpdate();
    },
    add() {
      let rows = [];
      for (let row of this.$refs.table.data.filter(el => {
        return el?.checked;
      })) {
        rows.push({
          good_id: row.id,
          name: row.name,
          category_id: row.category_id,
          amount: row.amount,

          amount_real: row.amount,
          measure: row.measure,
          price_plan: row.price,
          price_real: row.price,
          status: 0,
        });
      }
      this.$emit("save", rows);
      this.$emit("input");
    },
  },
};
</script>
