var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('dialog-edit',{attrs:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Выбор записей из Чек-листа ")]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('a-btn-save',{attrs:{"title":'Добавить'},on:{"click":function($event){return _vm.add()}}}),_c('v-btn',{on:{"click":function($event){return _vm.$emit('input')}}},[_vm._v("Закрыть")])]},proxy:true}])},[_c('v-card-text',{staticClass:"pa-0",staticStyle:{"height":"80vh","overflow":"auto"}},[(!true)?_c('a-table-f-api',{ref:"table",attrs:{"api":_vm.url,"model":_vm.modelGoods,"defaults":{
          sort: { key: 'id', order: 'DESC' },
          filters: {
            object_id: _vm.object_id,
            'buying.status': 201,
            doc_id: null,
          },
          paramName: 'goods.orderGoods',
        }},on:{"click":_vm.clickRow}}):_c('a-table-f-data2',{ref:"table",attrs:{"dataTable":_vm.data,"model":_vm.modelGoods,"useQuery":false,"defaults":{
          sort: { key: 'id', order: 'DESC' },
        }},on:{"click":function($event){return _vm.clickRow($event)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }